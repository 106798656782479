import "./App.css";
import { useState, useEffect } from "react";
import Main from "./components/Main";
//lib
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RefreshIcon from "@mui/icons-material/Refresh";
import axios from "axios";
import moment from "moment";
import GeneralReport from "./components/GeneralReport";

function App() {
  const apiURL = "https://babytrackr-server.lilcasoft.ca/api/";

  const babyBirthDay = "2021-12-12";

  const [babyActions, setBabyActions] = useState([]);
  const [reloadItem, setReloadItem] = useState(true);
  const [reportDate, setReportDate] = useState(moment().format("YYYY-MM-DD"));
  const [generalReport, setGeneralReport] = useState([]);

  const getBabyActions = async () => {
    const results = await axios.get(apiURL + "actions");
    setBabyActions(results.data);
    setReloadItem(false);
    document.querySelector(".loading-screen").style.display = "none";
  };

  const fetchGeneralReport = async (reportDate) => {
    const results = await axios.get(
      apiURL + "general_report/date/" + reportDate
    );
    setGeneralReport(results.data);
    setReloadItem(false);
    document.querySelector(".loading-screen").style.display = "none";
  };

  const addRecord = async (newRecord) => {
    await axios
      .post(apiURL + "records", newRecord)
      .then((response) => {
        if (response) {
          toast.success(response.data.msg, {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setReloadItem(true);
          document.querySelector(".loading-screen").style.display = "none";
        }
      })
      .catch((error) => {
        console.log("Error ========>", error);
      });
  };

  const updateRecord = async (record) => {
    await axios
      .put(apiURL + "records/id/" + record.id, record)
      .then((response) => {
        if (response) {
          toast.dismiss();
          toast.success(response.data.msg, {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setReloadItem(true);
          document.querySelector(".loading-screen").style.display = "none";
        }
      })
      .catch((error) => {
        console.log("Error ========>", error);
      });
  };

  const deleteRecord = async (record) => {
    await axios
      .delete(apiURL + "records/id/" + record.id, record)
      .then((response) => {
        if (response) {
          toast.success(response.data.msg, {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setReloadItem(true);
          document.querySelector(".loading-screen").style.display = "none";
        }
      })
      .catch((error) => {
        console.log("Error ========>", error);
      });
  };

  useEffect(() => {
    if (reloadItem) {
      document.querySelector(".loading-screen").style.display = "flex";
      getBabyActions();
    }
  }, [reloadItem]);

  return (
    <>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <Main
                  babyBirthDay={babyBirthDay}
                  addRecord={addRecord}
                  updateRecord={updateRecord}
                  deleteRecord={deleteRecord}
                  babyActions={babyActions}
                  setReloadItem={setReloadItem}
                  reportDate={reportDate}
                  setReportDate={setReportDate}
                />
              }
            />
            <Route
              path={"general_report/:reportDate"}
              element={
                <GeneralReport
                  reloadItem={reloadItem}
                  setReloadItem={setReloadItem}
                  reportDate={reportDate}
                  setReportDate={setReportDate}
                  generalReport={generalReport}
                  fetchGeneralReport={fetchGeneralReport}
                />
              }
            ></Route>
          </Routes>
        </BrowserRouter>
      </div>
      <div className="loading-screen">
        <RefreshIcon className="loading-screen-icon" />
        <span style={{ margin: "0 5px" }}>
          Processing your request...Please wait!!
        </span>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
