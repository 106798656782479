import React, { useEffect, useState } from "react";
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate, useParams } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import Typography from "@material-ui/core/Typography";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const GeneralReport = (props) => {
  let params = useParams();
  let navigate = useNavigate();

  const htmlToCSV = (html, filename) => {
    var data = [];
    var rows = document.querySelectorAll("table tr");

    for (var i = 0; i < rows.length; i++) {
      var row = [],
        cols = rows[i].querySelectorAll("td, th");

      for (var j = 0; j < cols.length; j++) {
        row.push(cols[j].innerText.replace(/,/g, ";"));
      }

      data.push(row.join(","));
    }
    downloadCSVFile(data.join("\n"), filename);
  };

  function downloadCSVFile(csv, filename) {
    var csv_file, download_link;

    csv_file = new Blob(["\ufeff" + csv], { type: "text/csv;charset=utf-8" });

    download_link = document.createElement("a");

    download_link.download = filename;

    download_link.href = window.URL.createObjectURL(csv_file);

    download_link.style.display = "none";

    document.body.appendChild(download_link);

    download_link.click();
  }

  useEffect(() => {
    if (props.reloadItem) {
      document.querySelector(".loading-screen").style.display = "flex";
      props.fetchGeneralReport(params.reportDate);
    }
  }, [props.reloadItem]);

  useEffect(() => {
    window.addEventListener("scroll", function (e) {
      var windowTopPos =
        window.pageYOffset || document.documentElement.scrollTop;
      var reportTblTopPos = document.querySelector("table").offsetTop;

      if (windowTopPos >= reportTblTopPos) {
        this.document.querySelector("table thead").style.transform =
          "translateY(" + (windowTopPos - reportTblTopPos) + "px)";
        this.document.querySelector("table thead").style.backgroundColor =
          "#fff";
      } else {
        this.document.querySelector("table thead").style.transform =
          "translateY(0)";
        this.document.querySelector("table thead").style.backgroundColor =
          "#fff";
      }
    });
  });

  return (
    <>
      {props.generalReport.length ? (
        <>
          <Typography m={2} variant="h4" gutterBottom>
            Rosie Monthly Report -{" "}
            {moment(params.reportDate).format("MMMM YYYY")}
          </Typography>
          <ButtonGroup
            disableElevation
            variant="contained"
            style={{
              margin: "15px 0",
              padding: "0px 10px",
              display: "flex",
            }}
          >
            <Button
              style={{ color: "#fff", border: "1px solid #fff" }}
              onClick={(e) => {
                navigate(`/`);
              }}
            >
              <ArrowBackIosIcon /> Back
            </Button>
            <Button
              style={{ color: "#fff", border: "1px solid #fff" }}
              onClick={(e) => {
                var html = document.querySelector("table").outerHTML;
                htmlToCSV(
                  html,
                  "Rosie Monthly Report " +
                    moment(params.reportDate).format("MM-YYYY") +
                    ".csv"
                );
              }}
            >
              <FileDownloadIcon /> Export CSV File
            </Button>
          </ButtonGroup>
          <TableContainer
            component={Paper}
            className="chamcongApp__generalReport_tablewrp"
          >
            <Table
              className="chamcongApp__generalReport_table"
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell key="note_0" style={{ width: "40%" }}>
                    Note
                  </TableCell>
                  <TableCell key="datetime_0" style={{ width: "40%" }}>
                    Date Time
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.generalReport.map((reportRow) => (
                  <TableRow key={reportRow.id}>
                    <TableCell className="nhanvien-row">
                      {reportRow.note}
                    </TableCell>
                    <TableCell className="nhanvien-row">
                      {moment(reportRow.action_datetime).format(
                        "DD MMM YYYY hh:mm A"
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        "Loading...."
      )}
    </>
  );
};

export default GeneralReport;
