import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Input from "@mui/material/Input";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateAdapter from "@mui/lab/AdapterMoment";
import DateTimePicker from "@mui/lab/DateTimePicker";
import TextField from "@mui/material/TextField";
import ButtonGroup from "@mui/material/ButtonGroup";
import moment from "moment";
import Badge from "@mui/material/Badge";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import EditIcon from "@mui/icons-material/Edit";

const BabyAction = (props) => {
  const [note, setNote] = useState("");
  const [selectedAction, setSelectedAction] = useState({});
  const [dateTimeVal, setDateTimeVal] = useState(new Date());
  const [openModal, setOpenModal] = useState(false);
  const [isTodayChecked, setIsTodayChecked] = useState(true);

  const [updateDateTimeVal, setUpdateDateTimeVal] = useState(new Date());

  const [editNote, setEditNote] = useState("");
  const [selectEditRow, setSelectEditRow] = useState({});
  const [openAddNoteModal, setOpenAddNoteModal] = useState(false);
  const handleOpenAddNoteModal = () => {
    setOpenAddNoteModal(true);
  };
  const handleCloseAddNoteModal = () => setOpenAddNoteModal(false);

  const feedingHours = 3;

  const handleIsTodayChecked = (event) => {
    setIsTodayChecked(event.target.checked);
  };

  const handleModalOpen = (babyItem) => {
    setSelectedAction(babyItem);
    setDateTimeVal(new Date());
    setOpenModal(true);
    setIsTodayChecked(!!babyItem.show_today_only);
  };
  const handleModalClose = () => setOpenModal(false);

  const handleDateTimeChange = (newValue) => {
    setDateTimeVal(newValue);
  };

  const handleUpdateDateTimeChange = (newValue) => {
    setUpdateDateTimeVal(newValue);
  };

  const handleNoteChange = (newValue) => {
    setNote(newValue);
  };

  const updateExistRow = (currRow) => {
    const formData = {
      id: currRow.id,
      action_id: currRow.action_id,
      note: editNote,
      action_datetime: moment(updateDateTimeVal).format("YYYY-MM-DD HH:mm:ss"),
    };
    props.updateRecord(formData);
    setEditNote("");
  };

  const savingRecord = () => {
    const formData = {
      action_id: selectedAction.id,
      note: note,
      action_datetime: moment(dateTimeVal).format("YYYY-MM-DD HH:mm:ss"),
    };

    props.addRecord(formData);
    setOpenModal(false);
  };

  const handleDeleteRecord = (record) => {
    if (window.confirm("Do you want to delete this record?") === true) {
      props.deleteRecord(record);
    } else {
      //ntd
    }
  };

  const nextFeedSuggestion = (lastFeed) => {
    return moment(lastFeed).add(feedingHours, "hours").format("hh:mm A");
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 400,
    width: "80%",
    bgcolor: "background.paper",
    color: "#000",
    p: 4,
  };

  return (
    <>
      <div
        onClick={() => handleModalOpen(props.babyItem)}
        className="babyTrackr__wrapper__item"
      >
        <Badge
          color="error"
          badgeContent={props.babyItem.records_count}
          max={999}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          {props.babyItem.title}
        </Badge>
      </div>
      <Modal
        open={openModal}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h6">
            Record Activity - {props.babyItem.title}
          </Typography>
          <Stack style={{ margin: "15px 0" }}>
            {props.babyItem.records.length ? (
              <Alert
                severity="info"
                variant="outlined"
                style={{ marginBottom: "10px" }}
              >
                {"Last " +
                  props.babyItem.title +
                  " record was at " +
                  (isTodayChecked
                    ? props.babyItem.records.length
                      ? moment(
                          props.babyItem.records[0].action_datetime
                        ).format("hh:mm A")
                      : ""
                    : props.babyItem.records.length
                    ? moment(props.babyItem.records[0].action_datetime).format(
                        "DD MMM YYYY"
                      )
                    : "")}
              </Alert>
            ) : (
              ""
            )}

            {[3].includes(props.babyItem.id) ? (
              <Alert
                variant="outlined"
                severity="info"
                style={{ marginBottom: "15px" }}
              >
                {props.babyItem.records.length
                  ? `Suggestion feed in the next ${feedingHours} hours at ${nextFeedSuggestion(
                      props.babyItem.records[0].action_datetime
                    )}`
                  : ""}
              </Alert>
            ) : (
              ""
            )}
          </Stack>

          <LocalizationProvider dateAdapter={DateAdapter}>
            <DateTimePicker
              label="Date&Time picker"
              value={dateTimeVal}
              onChange={handleDateTimeChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>

          <Input
            fullWidth
            placeholder="add note..."
            style={{ marginTop: "15px" }}
            onChange={(e) => handleNoteChange(e.target.value)}
          />

          <ButtonGroup
            variant="contained"
            aria-label="outlined primary button group"
            style={{ marginTop: "15px" }}
          >
            <Button onClick={savingRecord}>
              <AddIcon />
            </Button>
            <Button onClick={handleModalClose} color="error">
              <CloseIcon />
            </Button>
          </ButtonGroup>

          {props.babyItem.records.length > 0 ? (
            <>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                style={{ marginTop: "15px" }}
              >
                <Typography
                  id="modal-modal-title"
                  variant="body1"
                  component="div"
                  style={{ fontWeight: "bold" }}
                >
                  {isTodayChecked
                    ? "Today Activities"
                    : "This Month Activities"}
                </Typography>

                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isTodayChecked}
                        onChange={handleIsTodayChecked}
                      />
                    }
                    label={isTodayChecked ? "Today" : "This Month"}
                  />
                </FormGroup>
              </Stack>

              <List
                sx={{ width: "100%", bgcolor: "background.paper" }}
                style={{ maxHeight: "300px", overflowY: "scroll" }}
              >
                {isTodayChecked
                  ? props.babyItem.records
                      .filter(
                        (filterItem) =>
                          moment(filterItem.action_datetime).format(
                            "YYYY-MM-DD"
                          ) === moment().format("YYYY-MM-DD")
                      )
                      .map((todayItem, todayItemIndex) => (
                        <ListItem
                          key={todayItemIndex}
                          style={{
                            alignItems: "flex-start",
                            borderBottom: "1px solid #ddd",
                          }}
                        >
                          <ListItemText
                            primary={moment(todayItem.action_datetime).format(
                              "hh:mm A"
                            )}
                          />
                          <ListItemText
                            primary={todayItem.note}
                            style={{ flex: "2" }}
                          />
                          <ButtonGroup
                            variant="outlined"
                            aria-label="outlined primary button group"
                          >
                            <Button
                              size="small"
                              onClick={(e) => {
                                setUpdateDateTimeVal(todayItem.action_datetime);
                                setEditNote(todayItem.note);
                                setSelectEditRow(todayItem);
                                handleOpenAddNoteModal();
                              }}
                              color="primary"
                            >
                              <EditIcon />
                            </Button>

                            <Button
                              size="small"
                              onClick={() => {
                                handleDeleteRecord(todayItem);
                              }}
                              color="error"
                            >
                              <RemoveCircleOutlineIcon />
                            </Button>
                          </ButtonGroup>
                        </ListItem>
                      ))
                  : //show today only
                    props.babyItem.records.map((fullItem, fullItemIndex) => (
                      <ListItem
                        key={fullItemIndex}
                        style={{
                          alignItems: "flex-start",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        <ListItemText
                          primary={moment(fullItem.action_datetime).format(
                            "DD MMM YYYY"
                          )}
                        />
                        <ListItemText
                          primary={moment(fullItem.action_datetime).format(
                            "hh:mm A"
                          )}
                          style={{ flex: "2" }}
                          secondary={fullItem.note}
                        />
                        <Button
                          onClick={() => {
                            handleDeleteRecord(fullItem);
                          }}
                          color="error"
                        >
                          <RemoveCircleOutlineIcon />
                        </Button>
                      </ListItem>
                    ))}
              </List>
            </>
          ) : (
            ""
          )}
        </Box>
      </Modal>
      <Modal
        open={openAddNoteModal}
        onClose={handleCloseAddNoteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" mb={2}>
            Update Record
          </Typography>
          <LocalizationProvider dateAdapter={DateAdapter}>
            <DateTimePicker
              label="Date&Time picker"
              value={updateDateTimeVal}
              onChange={handleUpdateDateTimeChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <input
            className="clean-input"
            type="text"
            value={editNote}
            placeholder="update note..."
            onChange={(e) => setEditNote(e.target.value)}
          />
          <ButtonGroup variant="contained" style={{ margin: "15px 0" }}>
            <Button
              color="primary"
              onClick={(e) => {
                updateExistRow(selectEditRow);
                handleCloseAddNoteModal();
              }}
            >
              <EditIcon />
            </Button>
            <Button
              color="error"
              onClick={(e) => {
                setEditNote("");
                setOpenAddNoteModal(false);
              }}
            >
              <CloseIcon />
            </Button>
          </ButtonGroup>
        </Box>
      </Modal>
    </>
  );
};

export default BabyAction;
