import React, { useState } from "react";
import BabyAction from "./BabyAction";
import Typography from "@mui/material/Typography";
import moment from "moment";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import CachedIcon from "@mui/icons-material/Cached";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import TextField from "@mui/material/TextField";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import packageJson from "../../package.json";

const Main = (props) => {
  let navigate = useNavigate();

  const [openFilterDateModal, setOpenFilterDateModal] = useState(false);
  const handleOpenFilterDateModal = () => setOpenFilterDateModal(true);
  const handleCloseFilterDateModal = () => setOpenFilterDateModal(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    color: "#000",
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
  };

  var moment = require("moment-timezone");

  const getBabyDay = () => {
    const todayDate = moment().format("YYYY-MM-DD");
    var babyDay = moment(todayDate).diff(moment(props.babyBirthDay), "days");

    var totalDay = moment(todayDate).diff(moment(props.babyBirthDay), "days");
    let months = 0,
      years = 0,
      days = 0,
      weeks = 0;
    while (babyDay) {
      if (babyDay >= 365) {
        years++;
        babyDay -= 365;
      } else if (babyDay >= 30) {
        months++;
        babyDay -= 30;
      } else if (babyDay >= 7) {
        weeks++;
        babyDay -= 7;
      } else {
        days++;
        babyDay--;
      }
    }

    var displayBabyAge = [];

    if (years > 0) {
      displayBabyAge.push(`${years} year${years <= 1 ? "" : "s"}`);
    }
    if (months > 0) {
      displayBabyAge.push(`${months} month${months <= 1 ? "" : "s"}`);
    }
    if (weeks > 0) {
      displayBabyAge.push(`${weeks} week${weeks <= 1 ? "" : "s"}`);
    }
    if (days > 0) {
      displayBabyAge.push(`${days} day${days <= 1 ? "" : "s"}`);
    }

    return `${
      displayBabyAge.length ? displayBabyAge.join(" ") : "s"
    } <br>- ${totalDay} days -`;
  };

  return (
    <>
      <Avatar
        alt="Remy Sharp"
        src="/baby_avatar_2.jpg"
        sx={{ width: 56, height: 56 }}
      />
      <Typography
        className="babyTrackr__title"
        variant="h5"
        component="div"
        gutterBottom
      >
        Rosie Logs
      </Typography>

      <div
        className="babyTrackr__title"
        style={{
          textAlign: "center",
          fontSize: "18px",
          fontWeight: "400",
          marginBottom: "5px",
        }}
        dangerouslySetInnerHTML={{ __html: getBabyDay() }}
      />

      <Typography
        className="babyTrackr__title"
        variant="overline"
        component="div"
        gutterBottom
      >
        Today is {moment().format("Do MMMM YYYY")}
      </Typography>

      <Stack direction="row">
        <Button
          variant="text"
          style={{ color: "#fff" }}
          onClick={handleOpenFilterDateModal}
        >
          <AnalyticsIcon />
        </Button>
        <Button
          variant="text"
          style={{ color: "#fff" }}
          onClick={() => {
            toast.success("Data reload successfully!", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            props.setReloadItem(true);
          }}
        >
          <CachedIcon style={{ fontSize: "2em" }} />
        </Button>
      </Stack>

      <div className="babyTrackr__wrapper">
        {props.babyActions.length
          ? props.babyActions.map((actionItem, actionItemIndex) => (
              <BabyAction
                updateRecord={props.updateRecord}
                deleteRecord={props.deleteRecord}
                addRecord={props.addRecord}
                key={actionItemIndex}
                babyItem={actionItem}
              />
            ))
          : "Loading actions..."}
        <p style={{ textAlign: "center", width: "100%" }}>
          {`Version ${packageJson.version} - `}
          <a style={{ color: "#fff" }} href="https://lilcasoft.ca">
            lilcasoft.ca
          </a>
        </p>
      </div>

      <Modal
        open={openFilterDateModal}
        onClose={handleCloseFilterDateModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ marginBottom: "15px" }}
          >
            View Monthly Report
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              views={["year", "month"]}
              label="Year and Month"
              value={props.reportDate}
              onChange={(newValue) => {
                props.setReportDate(moment(newValue).format("YYYY-MM-DD"));
              }}
              renderInput={(params) => (
                <TextField {...params} helperText={null} />
              )}
            />
          </LocalizationProvider>
          <ButtonGroup variant="contained" style={{ margin: "15px 0" }}>
            <Button
              onClick={(e) => {
                if (props.reportDate === "") {
                  alert("Please choose month and year to view report!");
                } else {
                  props.setReloadItem(true);
                  navigate(`/general_report/${props.reportDate}`);
                }
              }}
            >
              View
            </Button>
            <Button
              color="error"
              onClick={(e) => setOpenFilterDateModal(false)}
            >
              Close
            </Button>
          </ButtonGroup>
        </Box>
      </Modal>
    </>
  );
};

export default Main;
